import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from 'common/src/components/Text';

const ButtonOuter = styled.a`
  display: inline-block;
  position: relative;
  border-radius: 10px;
  background: #015788;

  padding: 12px 60px;

  font-weight: 600;
  font-family: "Montserrat Alternates";
  font-size: 18px;
  color: #FFF;

  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
  transition: all 0.2s ease;

  &:hover {
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.45));
  }
`;

const LinkButton = ({
  title,
  href,
  target,
  styling
}) => {

  return (
    <ButtonOuter href={href ? href : null} target={target ? target : null} style={styling}>

      <Text
        as="span"
        content={title}
      />
    </ButtonOuter>
  );
};

LinkButton.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  target: PropTypes.string,
  styling: PropTypes.object
};

export default LinkButton;